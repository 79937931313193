import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import clienteAxios from '../../../../config/axios';
import Alert from '@material-ui/lab/Alert';
import {ListaIntereses} from '../../../../InteresesListado.js';
import {CategoriasListado} from '../../../../CategoriasListado.js';

import {
  Input,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip, FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const mensajeSwal = withReactContent(Swal);


  useEffect(() => {
    rellenarCamposEdicion();
    // eslint-disable-next-line
  }, []);

  async function rellenarCamposEdicion() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    if(id) {
      setEditandoId(id);
      await clienteAxios.get(`/api/logos/id/${id}`)
        .then(resultado => {
          console.log(resultado)
          setValues(resultado.data.resp)

        })
        .catch(error => {
          console.log(error)
        })
    }


  }

  const [errores, setErrores] = useState(null);
  const [editandoId, setEditandoId] = useState(null);
  const [values, setValues] = useState({
      nombre: '',
      imagen: '',
      link: '',
      categoria: '',
      orden: '',
      tamano: ''
    });
  const [openLogo, setOpenLogo] = useState(false);
  const [fileObjectsLogo, setFileObjectsLogo] = useState([]);


  const onSaveImagenLogo = async () => {
    setOpenLogo(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsLogo[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();
  
    if(editandoId === null) {
      crearLogo();
    } else {
      editarLogo();
    }
  }

  async function editarLogo() {
    try {
      const postEvento = await clienteAxios.put(`/api/logos/${values.id}`, values);
      mensajeSwal.fire({
        title: '¡Excelente!',
        text: `El logo fue actualizado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(()=> {
        window.location.replace(`/logos`);
      });
    } catch (error) {
      console.log(error);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `Hubo un error`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  async function crearLogo() {
    try {
      const postEvento = await clienteAxios.post(`/api/logos/`, values);
      mensajeSwal.fire({
        title: '¡Excelente!',
        text: `El logo fue creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(()=> {
        window.location.replace(`/logos`);
      });
    } catch (error) {
      console.log(error);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `Hubo un error`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        method="post"
        noValidate
        onSubmit={(e) => enviarFormulario(e)}
      >
        <CardContent>
        <CardHeader
          title={`${editandoId === null ? 'AÑADIR LOGO' : 'EDITAR LOGO'}`}
        />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nombre"
                margin="dense"
                name="nombre"
                onChange={handleChange}
                required
                value={values.nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="Link"
                margin="dense"
                name="link"
                onChange={handleChange}
                value={values.link}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={4}
              xs={6}
            >
              <TextField
              fullWidth
              label="Categoría"
              margin="dense"
              name="categoria"
              onChange={handleChange}
              required
              select
              // style={{marginRight: '20px'}}
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.categoria}
              variant="outlined"
            >
              <option value={0}>- Seleccionar -</option>
              <option value={1}>Empresas</option>
              <option value={2}>Instituciones</option>
              <option value={3}>Media Partners</option>
            </TextField>
          </Grid>
          <Grid
              item
              lg={1}
              md={4}
              xs={6}
            >
              <TextField
              fullWidth
              label="Tamaño"
              margin="dense"
              name="tamano"
              onChange={handleChange}
              required
              select
              // style={{marginRight: '20px'}}
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.tamano}
              variant="outlined"
            >
              <option value={0}>- Seleccionar -</option>
              <option value={1}>S</option>
              <option value={2}>M</option>
              <option value={3}>L</option>
              <option value={4}>XL</option>
            </TextField>
          </Grid>
            <Grid
              item
              lg={3}
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="Orden"
                margin="dense"
                name="orden"
                type="number"
                helperText="Se recomienda añadir con distancia de 100."
                onChange={handleChange}
                required
                value={values.orden}
                variant="outlined"
              />
            </Grid>
            <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setOpenLogo(true)}>
                          {
                            (values.imagen === '') ? 'Subir logo' : 'Cambiar logo'
                          }                    
                          </Button>

                      <DropzoneDialog
                        acceptedFiles={['image/*']}
                        fileObjects={fileObjectsLogo}
                        cancelButtonText={"Cancelar"}
                        submitButtonText={"Adjuntar"}
                        dialogTitle={"Subir imagen"}
                        dropzoneText={"Hacé clic o arrastrá el archivo"}
                        maxFileSize={1048576} // 5 MB
                        filesLimit={1}
                        open={openLogo}
                        onDrop={(nuevoArchivo) => {
                          setFileObjectsLogo(nuevoArchivo);
                        }}
                        onDelete={deleteFileObj => {
                          console.log('onDelete', deleteFileObj);
                        }}
                        onClose={() => setOpenLogo(false)}
                        onSave={() => {
                          onSaveImagenLogo();
                        }}
                        showPreviews={false}
                        showPreviewsInDropzone={true}
                        showFileNamesInPreview={true}
                      />
                    </div>
                  </Grid>
          </Grid> 
          </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            {editandoId === null ? 'CREAR' : 'ACTUALIZAR'}
          </Button>
          
          {errores ? <Alert severity="error">{errores}</Alert> : null}
        </CardActions>
      </form>
    </Card>

  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
