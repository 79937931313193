import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Grid, Typography, Button, TextField } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { ProductsToolbar, ProductCard } from './components';
import clienteAxios from '../../config/axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const ProductList = () => {
  const classes = useStyles();

  const [conferencias, setConferencias] = useState([]);
  const [busqueda, setBusqueda] = useState('');
  const [idEventoSeleccionado, setIdEventoSeleccionado] = useState(0);
  const [eventos, setEventos] = useState('')

  useEffect(() => {
    traerConferencias();
    traerEventos();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('evento')) {
      setIdEventoSeleccionado(urlParams.get('evento'))
    }
    // eslint-disable-next-line
  }, [])

  function handleChange(event) {
    setIdEventoSeleccionado(event.target.value);
  }

  async function traerConferencias() {
    await clienteAxios.get('/api/charlas/todas')
      .then(respuesta => {
        console.log(respuesta.data.resp)
        if(respuesta.data.resp) {
          const ordenado = respuesta.data.resp.sort(function(a, b){
            if(a.es_titulo < b.es_titulo) return -1;
            if(a.es_titulo > b.es_titulo) return 1;
          })
          setConferencias(ordenado)
        }
      })
  }

  async function traerEventos() {
    await clienteAxios.get('/api/eventos/todos')
      .then(respuesta => {
        if(respuesta.data.resp) {
          const ordenado = respuesta.data.resp.sort(function(a, b){
            if(a.id > b.id) return -1;
            if(a.id < b.id) return 1;
          })
          setEventos(ordenado)
        }
      })
  }

  // const { className, ...rest } = props;

  const handleChangeBuscador = (e) => {
    setBusqueda(e.target.value)
  }

  return (
    <div className={classes.root}>
      <div
        // {...rest}
        // className={clsx(classes.root, className)}
        className={clsx(classes.root)}
        style={{marginBottom: '2rem'}}
      >
        <div className={classes.row}>
          <TextField
            fullWidth
            label="Evento"
            margin="dense"
            name="evento"
            onChange={handleChange}
            required
            select
            style={{marginRight: '20px'}}
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={idEventoSeleccionado}
            variant="outlined"
          >
            <option value={0}>Todos</option>
            {
              eventos ? (
                eventos.map(ev => {
                  return (
                    <option key={ev.id} value={ev.id}>{ev.es_nombre}: ({ev.inicio} a {ev.fin})</option>
                  )}
                )
              ) : null
            }
          </TextField>
          <span className={classes.spacer} />
          <Link to={`/nueva-conferencia?evento=${idEventoSeleccionado}`}>
            <Button
              style={{width: '12rem'}}
              color="primary"
              variant="contained"
            >
              Nueva conferencia
            </Button>
          </Link>
        </div>
      </div>
      <input
        type="text"
        placeholder="Escribí para buscar..."
        onChange={handleChangeBuscador}
        value={busqueda}
        style={{
          marginBottom: '20px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: '1px solid black',
          fontSize: '16px',
          padding: '8px',
          marginTop: '-40px'
        }}
        />
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
        >
          {conferencias.map(conferencia => {
            if( (idEventoSeleccionado == 0 || conferencia.evento == idEventoSeleccionado) && (
                (busqueda === '' || busqueda === null) || 
                conferencia.es_titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                conferencia.es_breve_descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                conferencia.nombre_empresa.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                conferencia.orador_nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                conferencia.slug.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                conferencia.orador_apellido.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase())
              )
            ) {
              return (
                <Grid
                  item
                  key={conferencia.id}
                  md={4}
                  xs={12}
                >
                  <ProductCard product={conferencia} />
                </Grid>
              )
            }
          })}
        </Grid>
      </div>

          <div></div>
      <div className="globo-nueva-conferencia">
          <Link to="/nueva-conferencia">
            <Button
              color="primary"
              variant="contained"
            >
              Nueva conferencia
            </Button>
          </Link>
      </div>
      
    </div>
  );
};

export default ProductList;
