import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import clienteAxios from '../../../../config/axios';
import Alert from '@material-ui/lab/Alert';
import {ListaIntereses} from '../../../../InteresesListado.js';
import {CategoriasListado} from '../../../../CategoriasListado.js';

import {
  Input,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip, FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  // useEffect(() => {
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);
  //   const evento = urlParams.get('evento');
  //   setValues({
  //     ...values,
  //     idEvento: evento
  //   });
  //   // eslint-disable-next-line
  // }, []);

  const [openImagenES, setOpenImagenES] = useState(false);
  const [openImagenEN, setOpenImagenEN] = useState(false);
  const [openImagenPO, setOpenImagenPO] = useState(false);

  const [fileObjectsImagenES, setFileObjectsImagenES] = useState([]);
  const [fileObjectsImagenEN, setFileObjectsImagenEN] = useState([]);
  const [fileObjectsImagenPO, setFileObjectsImagenPO] = useState([]);

  const [errores, setErrores] = useState(null);
  const [values, setValues] = useState({
    id: 0,
    es_nombre: '',
    en_nombre: '',
    po_nombre: '',
    es_descripcion: '',
    en_descripcion: '',
    po_descripcion: '',
    slug: '',
    inicio: '',
    fin: '',
    es_imagen: '',
    en_imagen: '',
    po_imagen: ''
  });


  const handleChange = event => {
    if(event.target.name === 'slug') {
      setValues({
        ...values,
        [event.target.name]: ((event.target.value).replace(/\s/g, '-').toLowerCase())
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const onSaveImagenES = async () => {
    setOpenImagenES(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsImagenES[0]);
        
    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        es_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenEN = async () => {
    setOpenImagenEN(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsImagenEN[0]);
        
    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        en_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPO = async () => {
    setOpenImagenPO(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsImagenPO[0]);
        
    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        po_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const enviarFormulario = async (e) => {
    e.preventDefault();
  
    const mensajeSwal = withReactContent(Swal);

    try {
      const postEvento = await clienteAxios.post('/api/eventos', values);

      mensajeSwal.fire({
        title: '¡Excelente!',
        text: `El evento fue creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(()=> {
        window.location.replace(`/eventos?evento=${postEvento.data.resp.id}`);
      });
    } catch (error) {
      console.log(error);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `Hubo un error`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        method="post"
        noValidate
        onSubmit={(e) => enviarFormulario(e)}
      >
        <CardContent>
        <CardHeader
          subheader="Información"
          title="EVENTO"
        />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="En español"
                label="ES - Nombre del evento"
                margin="dense"
                name="es_nombre"
                onChange={handleChange}
                required
                value={values.es_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="En inglés"
                label="EN - Nombre del evento"
                margin="dense"
                name="en_nombre"
                onChange={handleChange}
                value={values.en_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="En portugués"
                label="PR - Nombre del evento"
                margin="dense"
                name="po_nombre"
                onChange={handleChange}
                value={values.po_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Ejemplo: mi-nuevo-evento"
                label="Slug"
                margin="dense"
                name="slug"
                onChange={handleChange}
                required
                value={values.slug}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="ES - Descripción"
                margin="dense"
                name="es_descripcion"
                onChange={handleChange}
                required
                type="text"
                multiline={true}
                value={values.es_descripcion}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="EN - Descripción"
                margin="dense"
                name="en_descripcion"
                onChange={handleChange}
                required
                type="text"
                multiline={true}
                value={values.en_descripcion}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="PR - Descripción"
                margin="dense"
                name="po_descripcion"
                onChange={handleChange}
                required
                type="text"
                multiline={true}
                value={values.po_descripcion}
                variant="outlined"
              />
            </Grid>
            </Grid>
            <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
            <Grid container spacing={3}>
            <Grid
              item
              md={3}
              xs={3}
            >
              <TextField
                fullWidth
                helperText="Fecha de inicio"
                margin="dense"
                name="inicio"
                onChange={handleChange}
                required
                type="date"
                value={values.inicio}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
            >
              <TextField
                fullWidth
                helperText="Fecha de finalización"
                margin="dense"
                name="fin"
                onChange={handleChange}
                required
                type="date"
                value={values.fin}
                variant="outlined"
              />
            </Grid>
          </Grid> 
          <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenImagenES(true)}>
                    {
                      (values.es_imagen === '' || values.es_imagen === null) ? 'Subir imagen ES' : 'Cambiar imagen ES'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsImagenES}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen, hasta 1MB"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={1050000} // 1 MB
                  filesLimit={1}
                  open={openImagenES}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsImagenES(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenImagenES(false)}
                  onSave={() => {
                    onSaveImagenES();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenImagenEN(true)}>
                    {
                      (values.en_imagen === '' || values.en_imagen === null) ? 'Subir imagen EN' : 'Cambiar imagen EN'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsImagenEN}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen, hasta 1MB"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={1050000} // 1 MB
                  filesLimit={1}
                  open={openImagenEN}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsImagenEN(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenImagenEN(false)}
                  onSave={() => {
                    onSaveImagenEN();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenImagenPO(true)}>
                    {
                      (values.po_imagen === '' || values.po_imagen === null) ? 'Subir imagen PR' : 'Cambiar imagen PR'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsImagenPO}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen, hasta 1MB"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={1050000} // 1 MB
                  filesLimit={1}
                  open={openImagenPO}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsImagenPO(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenImagenPO(false)}
                  onSave={() => {
                    onSaveImagenPO();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            </Grid>
          </CardContent>

        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Guardar
          </Button>
          
          {errores ? <Alert severity="error">{errores}</Alert> : null}
        </CardActions>
      </form>
    </Card>

  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
