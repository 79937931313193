import React, { useState, useEffect } from 'react';
import { IconButton, Grid, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { UsersToolbar, UserCard } from './components';
import clienteAxios from '../../config/axios';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if(stabilizedThis) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  } else {
    return
  }
}

const headCells = [
  // { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  { id: 'es_imagen', numeric: false, disablePadding: true, label: 'Imagen' },
  { id: 'link', numeric: false, disablePadding: false, label: 'Link' },
  { id: 'order', numeric: false, disablePadding: false, label: 'Orden' },
  { id: 'acciones', numeric: true, disablePadding: false, label: 'Acciones' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const Eventos = () => {

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('order');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [items, setItems] = useState([]);

  useEffect(() => {
    traerBanners();
    // eslint-disable-next-line
  }, []);

  const traerBanners = async () => {
    await clienteAxios.get('/api/banners/todos')
      .then(banners => {
        setItems(banners.data.resp);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const ConfirmacionSwal = withReactContent(Swal)
  
  const eliminarBtn = (id) => {
    ConfirmacionSwal.fire({
      title: '¿Seguro querés borrarlo?',
      text: `Si lo eliminas, no podes recuperarlo`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0E3453',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then( async (result)  => {
      if(result.value){
          await clienteAxios.delete(`/api/banners/id/${id}`)
            .then(resp => {
              ConfirmacionSwal.fire({
                title: 'Eliminado con éxito',
                text: "Listo, ya lo eliminaste",
                icon: 'success',
                timer: 2000,
              }).then( () => {
                window.location.reload(false);
              });
            })
          .catch(error => {
            console.log(error)
            ConfirmacionSwal.fire({
              title: 'Ups!',
              text: "No pudimos eliminar el banner",
              icon: 'error',
              timer: 2000,
            });
          })
      }
    }); 
  }
  

  const editarItem = id => {
    window.location.href = `/gestionar-banners?id=${id}`;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, items?.length - page * rowsPerPage);

  const [num, setNum] = useState(1);
  const [busqueda, setBusqueda] = useState(null);

  const handleChangeBuscador = (e) => {
    setBusqueda(e.target.value)
  }

  return (
    <div className={classes.root}>
      {/* <UsersToolbar /> */}
      <div className={classes.content}>
        
      <div className={classes.root}>
        <Link to="/gestionar-banners">
          <Button
            color="primary"
            variant="contained"
            style={{marginRight: '2rem', marginLeft: '0'}}
          >
            Nuevo banner
          </Button>
        </Link>
      <input
        type="text"
        placeholder="Escribí para buscar..."
        onChange={handleChangeBuscador}
        value={busqueda}
        style={{
          marginBottom: '20px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: '1px solid black',
          fontSize: '16px',
          padding: '8px'
        }}
        />

      <p style={{textAlign:"right", fontSize: '13px', fontFamily: "Poppins, sans-serif"}}><strong>Inicio: </strong>(nro de orden) 1-99 iniciales / banners de eventos / 100-199 finales</p>
      <p style={{textAlign:"right", fontSize: '13px', fontFamily: "Poppins, sans-serif"}}><strong>Biblioteca de contenidos: </strong>950 a 999.</p>
      <p style={{textAlign:"right", fontSize: '13px', fontFamily: "Poppins, sans-serif"}}>El orden es único y <strong>NO PUEDE REPETIRSE</strong>. Dará error si se repite.</p>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items?.length}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // const isItemSelected = isSelected(item.nombre);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log(item)

                  if (
                    (busqueda === null || busqueda === '') || (
                    (busqueda !== null || busqueda !== '') && (
                      item.es_imagen.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                      item.link.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                      item.en_imagen.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase())))) {
                    
                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, item.nombre)}
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={item.id}
                            // selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              /> */}
                            </TableCell>
                            {/* <TableCell align="left">{item.id}</TableCell> */}
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <img src={item.es_imagen} style={{maxWidth: '100%', maxHeight: '10rem'}} />                              
                            </TableCell>
                            <TableCell align="left">{item.link}</TableCell>
                            <TableCell align="left">{item.order}</TableCell>
                            <TableCell align="right" width="15%">
                              <IconButton
                                onClick={() => eliminarBtn(item.id)}
                              ><DeleteIcon /></IconButton>
                              <IconButton
                                onClick={() => editarItem(item.id)}
                              ><EditIcon /></IconButton>
                            </TableCell>
                          </TableRow>
                        );
                  }
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50, 10000]}
          component="div"
          count={items?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={'Filas por página'}
        />
      </Paper>
      </div>
      </div>
    </div>
  );
};

export default Eventos;