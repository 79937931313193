import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  InscripcionesList as InscripcionesListView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Agenda as AgendaView,
  Certificados as CertificadosView,
  NuevoAgenda as NuevoAgendaView,
  EditarAgenda as EditarAgendaView,
  Ingresos as IngresosView,
  Eventos as EventosView,
  NuevoEvento as NuevoEventoView,
  EditarEvento as EditarEventoView,
  Banners as BannersView,
  GestionarBanners as GestionarBannersView,
  Logos as LogosView,
  GestionarLogos as GestionarLogosView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <RouteWithLayout
        component={InscripcionesListView}
        exact
        layout={MainLayout}
        path="/inscripciones"
      />
      <RouteWithLayout
        component={EventosView}
        exact
        layout={MainLayout}
        path="/eventos"
      />
      <RouteWithLayout
        component={NuevoEventoView}
        exact
        layout={MainLayout}
        path="/nuevo-evento"
      />
      <RouteWithLayout
        component={EditarEventoView}
        exact
        layout={MainLayout}
        path="/editar-evento"
      />
      <RouteWithLayout
        component={AgendaView}
        exact
        layout={MainLayout}
        path="/conferencias"
      />
      <RouteWithLayout
        component={NuevoAgendaView}
        exact
        layout={MainLayout}
        path="/nueva-conferencia"
      />
      <RouteWithLayout
        component={IngresosView}
        exact
        layout={MainLayout}
        path="/ingresos"
      />
      <RouteWithLayout
        component={EditarAgendaView}
        exact
        layout={MainLayout}
        path="/editar-agenda"
      />
      <RouteWithLayout
        component={CertificadosView}
        exact
        layout={MainLayout}
        path="/certificados"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={BannersView}
        exact
        layout={MainLayout}
        path="/banners"
      />
      <RouteWithLayout
        component={LogosView}
        exact
        layout={MainLayout}
        path="/logos"
      />
      <RouteWithLayout
        component={GestionarLogosView}
        exact
        layout={MainLayout}
        path="/gestionar-logos"
      />
      <RouteWithLayout
        component={GestionarBannersView}
        exact
        layout={MainLayout}
        path="/gestionar-banners"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
