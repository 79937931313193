import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import clienteAxios from 'config/axios';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const ProductsToolbar = props => {

  useEffect(() => {
    async function traerEventos() {
      await clienteAxios.get('/api/eventos/todos')
        .then(respuesta => {
          if(respuesta.data.resp) {
            const ordenado = respuesta.data.resp.sort(function(a, b){
              if(a.id > b.id) return -1;
              if(a.id < b.id) return 1;
            })
            setEventos(ordenado)
          }
        })
    }
    traerEventos();
  }, [])

  function handleChange(event) {
    setIdEventoSeleccionado(event.target.value);
    window.location.href = `?evento=${event.target.value}`;
  }

  const { className, ...rest } = props;

  const classes = useStyles();

  const [idEventoSeleccionado, setIdEventoSeleccionado] = useState(0);
  const [eventos, setEventos] = useState('')

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{marginBottom: '2rem'}}
    >
      <div className={classes.row}>
        <TextField
          fullWidth
          label="Evento"
          margin="dense"
          name="evento"
          onChange={handleChange}
          required
          select
          style={{marginRight: '20px'}}
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={idEventoSeleccionado}
          variant="outlined"
        >
          <option value={0}>Todos</option>
          {
            eventos ? (
              eventos.map(ev => {
                return (
                  <option key={ev.id} value={ev.id}>{ev.es_nombre}: ({ev.inicio} a {ev.fin})</option>
                )}
              )
            ) : null
          }
        </TextField>
        <span className={classes.spacer} />
        <Link to={`/nueva-conferencia?evento=${idEventoSeleccionado}`}>
          <Button
            style={{width: '12rem'}}
            color="primary"
            variant="contained"
          >
            Nueva conferencia
          </Button>
        </Link>
      </div>
    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;