import React, { useState, useEffect } from 'react';
import { IconButton, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { UsersToolbar, UserCard } from './components';
import clienteAxios from '../../config/axios';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'nombre', numeric: false, disablePadding: true, label: 'Nombre' },
  { id: 'apellido', numeric: false, disablePadding: false, label: 'Apellido' },
  { id: 'pais', numeric: false, disablePadding: false, label: 'Pais' },
  { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
  { id: 'cargo', numeric: false, disablePadding: false, label: 'Cargo' },
  { id: 'empresa', numeric: false, disablePadding: false, label: 'Empresa' },
  { id: 'conferencia', numeric: false, disablePadding: false, label: 'Conferencia' },
  { id: 'idPago', numeric: false, disablePadding: false, label: 'ID Pago' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const Certificados = () => {

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('idPago');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const traerCertificados = async () => {
      const certificados = await clienteAxios.get('/api/certificados/all');
      setItems(certificados.data.resp);
      console.log(certificados.data.resp);
    }
    traerCertificados();
    // eslint-disable-next-line
  }, [])

  const ConfirmacionSwal = withReactContent(Swal);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  const [num, setNum] = useState(1);
  const [busqueda, setBusqueda] = useState(null);
  const [pagadosFiltro, setPagadosFiltro] = useState(true);
  const [pendientesFiltro, setPendientesFiltro] = useState(true);

  const handleChangeBuscador = (e) => {
    setBusqueda(e.target.value)
  }

  const handleChangeCheckboxPagados = e => {
    setPagadosFiltro(!pagadosFiltro)
  }
  const handleChangeCheckboxSolicitados = e => {
    setPendientesFiltro(!pendientesFiltro)
  }

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <div className={classes.content}>
        
      <div className={classes.root}>
      <input
        type="text"
        placeholder="Escribí para buscar..."
        onChange={handleChangeBuscador}
        value={busqueda}
        style={{
          marginBottom: '20px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: '1px solid black',
          fontSize: '16px',
          padding: '8px',
          marginRight: '20px'
        }}
        />

      {/* <FormControlLabel
        control={
          <Checkbox
          checked={pagadosFiltro}
          onChange={handleChangeCheckboxPagados}
              inputProps={{ 'aria-label': 'secondary checkbox' }}  
            name="pagadosFiltro"
            color="primary"
          />
        }
        label="Solo pagados"
      /> */}

      {/* <FormControlLabel
        control={
          <Checkbox
            checked={pendientesFiltro}
            onChange={handleChangeCheckboxSolicitados}
            inputProps={{ 'aria-label': 'secondary checkbox' }}  
            name="pendientesFiltro"
            color="primary"
          />
        }
        label="No pagos"
      /> */}

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // const isItemSelected = isSelected(item.nombre);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log(item)

                  if (
                    ((busqueda === null || busqueda === '') || (
                    (busqueda !== null || busqueda !== '') && (
                      item.usuario.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                      item.usuario.apellido.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) ||
                      item.charla.es_titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase())
                    )))) {
                    
                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, item.nombre)}
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={item.id}
                            // selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              /> */}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {item.usuario.nombre}
                            </TableCell>
                            <TableCell align="left">{item.usuario.apellido}</TableCell>
                            <TableCell align="left">{item.usuario.pais}</TableCell>
                            <TableCell align="left">
                            {
                              (item.certificado === '3' || item.certificado === '7') ? (
                                // PAGO
                                <CheckCircleIcon />
                                ) : (
                                // APTO
                                <QueryBuilderIcon />
                              )
                            }
                            </TableCell>


                            <TableCell align="left">{item.usuario.cargo.charAt(0).toUpperCase() + item.usuario.cargo.slice(1)}</TableCell>
                            <TableCell align="left">{item.usuario.empresa}</TableCell>
                            <TableCell align="left">{item.charla.es_titulo}</TableCell>
                            <TableCell align="left">
                              {
                                (item.certificado === '1' || item.certificado === 1) ? (
                                  ''
                                ) : item.certificado
                              }
                            </TableCell>

                            {/* <TableCell align="right" width="15%">
                              <IconButton
                                onClick={() => eliminarBtn(item.id)}
                              ><DeleteIcon /></IconButton>
                              <IconButton
                                onClick={() => editarItem(item.id)}
                              ><EditIcon /></IconButton>
                              {
                                (item.visible === true) ? (
                                  <IconButton
                                    onClick={() => cambiarVisibilidad(item.id) }
                                  ><VisibilityOffIcon /></IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => cambiarVisibilidad(item.id) }
                                  ><VisibilityIcon /></IconButton>
                                )
                              }
                            </TableCell> */}
                          </TableRow>
                        );
                  }
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50, 100, 300, 500, 1000, 2000, 5000, 10000]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={'Filas por página'}
        />
      </Paper>
      </div>
      </div>
    </div>
  );
};

export default Certificados;