import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import clienteAxios from '../../../../config/axios';
import Alert from '@material-ui/lab/Alert';
import {ListaIntereses} from '../../../../InteresesListado.js';
import {CategoriasListado} from '../../../../CategoriasListado.js';

import {
  Input,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Chip, FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  useEffect(() => {
    traerEventos();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const eventoParam = urlParams.get('evento');

    setTimeout(() => {
      setValues({
        ...values,
        evento: eventoParam
      });
    }, 1000);
    // eslint-disable-next-line
  }, []);

  async function traerEventos() {
    await clienteAxios.get('/api/eventos/todos')
      .then(respuesta => {
        if(respuesta.data.resp) {
          const ordenado = respuesta.data.resp.sort(function(a, b){
            if(a.id > b.id) return -1;
            if(a.id < b.id) return 1;
          })
          setEventos(ordenado)
        }
      })
  }

  const [openLogo, setOpenLogo] = useState(false);
  const [openPortada, setOpenPortada] = useState(false);
  const [openPortadaEN, setOpenPortadaEN] = useState(false);
  const [openPortadaPO, setOpenPortadaPO] = useState(false);
  const [openLogoSponsor, setOpenLogoSponsor] = useState(false);
  const [openPortadaBiblioteca, setOpenPortadaBiblioteca] = useState(false);
  const [openPortadaBibliotecaEN, setOpenPortadaBibliotecaEN] = useState(false);
  const [openPortadaBibliotecaPO, setOpenPortadaBibliotecaPO] = useState(false);

  const [openOrador, setOpenOrador] = useState(false);
  const [openOradorDos, setOpenOradorDos] = useState(false);
  const [openOradorTres, setOpenOradorTres] = useState(false);
  const [openOradorCuatro, setOpenOradorCuatro] = useState(false);


  const [fileObjectsPortada, setFileObjectsPortada] = useState([]);
  const [fileObjectsPortadaEN, setFileObjectsPortadaEN] = useState([]);
  const [fileObjectsPortadaPO, setFileObjectsPortadaPO] = useState([]);
  const [fileObjectsLogo, setFileObjectsLogo] = useState([]);
  const [fileObjectsOrador, setFileObjectsOrador] = useState([]);
  const [fileObjectsOradorDos, setFileObjectsOradorDos] = useState([]);
  const [fileObjectsOradorTres, setFileObjectsOradorTres] = useState([]);
  const [fileObjectsOradorCuatro, setFileObjectsOradorCuatro] = useState([]);
  const [fileObjectsLogoSponsor, setFileObjectsLogoSponsor] = useState([]);
  const [fileObjectsPortadaBiblioteca, setFileObjectsPortadaBiblioteca] = useState([]);
  const [fileObjectsPortadaBibliotecaEN, setFileObjectsPortadaBibliotecaEN] = useState([]);
  const [fileObjectsPortadaBibliotecaPO, setFileObjectsPortadaBibliotecaPO] = useState([]);


  const [eventos, setEventos] = useState([])
  const [errores, setErrores] = useState(null);
  const [values, setValues] = useState({
    es_titulo: '',
    evento: 0,
    slug: '',
    categoria: 1,
    categoriaTematicaUno: '',
    categoriaTematicaDos: '',
    categoriaTematicaTres: '',
    nombre_empresa: '',
    fecha: new Date(),
    hora: '',
    es_breve_descripcion: '',
    es_larga_descripcion: '',
    duracion: 90,
    logo_empresa: '',
    mostrar_logo_certificado: 0,
    portada_imagen: '',
    en_portada_imagen: '',
    po_portada_imagen: '',
    intereses: [],

    orador_nombre: '',
    orador_apellido: '',
    orador_linkedin: '',
    es_orador_cv: '',
    orador_imagen: '',

    dos_orador_nombre: '',
    dos_orador_apellido: '',
    dos_orador_linkedin: '',
    dos_es_orador_cv: '',
    dos_orador_imagen: '',

    tres_orador_nombre: '',
    tres_orador_apellido: '',
    tres_orador_linkedin: '',
    tres_es_orador_cv: '',
    tres_orador_imagen: '',

    cuatro_orador_nombre: '',
    cuatro_orador_apellido: '',
    cuatro_orador_linkedin: '',
    cuatro_es_orador_cv: '',
    cuatro_orador_imagen: '',

    wpp: '',
    youtube: '',

    zoom_link: '',
    inscriptos: '',
    e_plan: '',
    e_descuento: '',

    en_titulo: '',
    en_breve_descripcion: '',
    en_larga_descripcion: '',
    en_orador_cv: '',
    dos_en_orador_cv: '',
    tres_en_orador_cv: '',
    cuatro_en_orador_cv: '',

    po_titulo: '',
    po_breve_descripcion: '',
    po_larga_descripcion: '',
    po_orador_cv: '',
    dos_po_orador_cv: '',
    tres_po_orador_cv: '',
    cuatro_po_orador_cv: '',

    certificado_gratuito: '',
    certificado_sponsor: '',
    certificado_gratuito_desde: new Date(),
    certificado_gratuito_hasta: new Date(),

    b_es_titulo: "",
    b_en_titulo: "",
    b_po_titulo: "",
    b_es_descripcion: "",
    b_en_descripcion: "",
    b_po_descripcion: "",
    b_es_portada: "",
    b_en_portada: "",
    b_po_portada: "",
    b_destacado: "",
    b_patrocinado: "",
    b_orden_destacado: "",
    b_orden_patrocinado: ""
  });

  const planes = [
    {
      nombre: 'mastertalk',
      precio: 0
    },
    {
      nombre: 'basico',
      precio: 999
    },
    {
      nombre: 'intermedio',
      precio: 1999
    },
    {
      nombre: 'avanzado',
      precio: 3999
    },
    {
      nombre: 'premium',
      precio: 5999
    },
  ]

  const handleChange = event => {
    if(event.target.name === 'slug') {
      setValues({
        ...values,
        [event.target.name]: ((event.target.value).replace(/\s/g, '-').toLowerCase())
      });
    } if( event.target.name === "mostrar_logo_certificado" ||
          event.target.name === "certificado_gratuito" ||
          event.target.name === "b_destacado" ||
          event.target.name === "b_patrocinado"
        ) {
      setValues({
        ...values,
        [event.target.name]: event.target.checked
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeIntereses = (event) => {
    setValues({
      ...values,
      intereses: event.target.value
    });
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    // setPersonName(value);
  };

  const onSaveImagenPortada = async () => {
    setOpenPortada(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortada[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        portada_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPortadaEN = async () => {
    setOpenPortada(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortada[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        en_portada_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPortadaPO = async () => {
    setOpenPortada(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortada[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        po_portada_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPortadaBiblioteca = async () => {
    setOpenPortadaBiblioteca(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortadaBiblioteca[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        b_es_portada: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPortadaBibliotecaEN = async () => {
    setOpenPortadaBibliotecaEN(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortadaBibliotecaEN[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        b_en_portada: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenPortadaBibliotecaPO = async () => {
    setOpenPortadaBibliotecaPO(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsPortadaBibliotecaPO[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        b_po_portada: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  // TODO: unificar subidas de imagen!!!!!!

  const onSaveImagenLogo = async () => {
    setOpenLogo(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsLogo[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        logo_empresa: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenLogoSponsor = async () => {
    setOpenLogoSponsor(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsLogoSponsor[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        certificado_sponsor: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenOrador = async () => {
    setOpenOrador(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsOrador[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        orador_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenOradorDos = async () => {
    setOpenOradorDos(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsOradorDos[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        dos_orador_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenOradorTres = async () => {
    setOpenOradorTres(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsOradorTres[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        tres_orador_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const onSaveImagenOradorCuatro = async () => {
    setOpenOradorCuatro(false);

    // Crear formData
    const formDataImagen = new FormData();
    formDataImagen.append('archivo', fileObjectsOradorCuatro[0]);

    try {
      const subirImagen = await clienteAxios.post('/api/archivos', formDataImagen);
      setValues(prevState => ({
        ...prevState,
        cuatro_orador_imagen: subirImagen.data.archivo
      }));
    } catch (error) {
      const mensajeSwal = withReactContent(Swal);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `No pudimos subir la imagen`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  const enviarFormulario = async (e) => {
    e.preventDefault();
  
    const mensajeSwal = withReactContent(Swal);

    await clienteAxios.post('/api/charlas', values)
      .then(resp => {
        mensajeSwal.fire({
          title: '¡Excelente!',
          text: `La conferencia fue agregada con éxito`,
          icon: 'success',
          timer: 3000
        }).then(()=> {
          window.location.replace("/conferencias");
        });
      })
      .catch(error => {
        mensajeSwal.fire({
          title: 'Ups...',
          text: `Hubo un error`,
          icon: 'error',
          timer: 3000
        });
      })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        method="post"
        noValidate
        onSubmit={(e) => enviarFormulario(e)}
      >
        <CardHeader
          subheader="Conferencia"
          title="AGREGAR NUEVA"
        />
        <Divider />
        <CardContent>
        <CardHeader
              subheader="Información de la Conferencia / MasterTalk"
              title="CONFERENCIA"
            />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={3}
            >
              <TextField
                fullWidth
                label="Evento"
                margin="dense"
                name="evento"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.evento}
                variant="outlined"
              >
                {/* <option value={0} disabled selected>Seleccionar evento</option> */}
                {
                  eventos ? (
                    eventos.map(ev => (
                      <option value={ev.id}>{ev.es_nombre}: {ev.inicio} a {ev.fin}</option>
                    ))
                  ) : null
                }
              </TextField>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="En español"
                label="Título de la conferencia"
                margin="dense"
                name="es_titulo"
                onChange={handleChange}
                required
                value={values.es_titulo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Ejemplo: circulacion-en-epocas-de-pandemia"
                label="Slug"
                margin="dense"
                name="slug"
                onChange={handleChange}
                required
                value={values.slug}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
            >
              <TextField
                fullWidth
                label="Tipo de conferencia"
                margin="dense"
                name="categoria"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.categoria}
                variant="outlined"
              >
                <option value={1}>Master-Talk</option>
                <option value={2}>Conferencia</option>
              </TextField>
            </Grid>
            
            
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="Descripción corta"
                margin="dense"
                name="es_breve_descripcion"
                onChange={handleChange}
                required
                multiline={true}
                type="text"
                value={values.es_breve_descripcion}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="Descripción larga"
                margin="dense"
                name="es_larga_descripcion"
                onChange={handleChange}
                required
                multiline={true}
                type="text"
                value={values.es_larga_descripcion}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="Fecha"
                margin="dense"
                name="fecha"
                onChange={handleChange}
                required
                value={values.fecha}
                variant="outlined"
                id="date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="Hora"
                margin="dense"
                name="hora"
                onChange={handleChange}
                required
                type="time"
                value={values.hora}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="Duración (min)"
                margin="dense"
                name="duracion"
                onChange={handleChange}
                required
                type="number"
                value={values.duracion}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
              lg={3}
            >
              <FormControl 
                fullWidth
                margin="dense"
              >
                <InputLabel id="intereses-multiple">Intereses</InputLabel>
                <Select
                  fullWidth
                  margin="dense"
                  required
                  variant="outlined"
                  labelId="intereses-multiple"
                  id="intereses-chip"
                  multiple
                  value={values.intereses}
                  onChange={handleChangeIntereses}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  // MenuProps={MenuProps}
                >
                  {ListaIntereses.map((interes) => (
                    <MenuItem key={interes.ES} value={interes.COD}>
                      <Checkbox checked={values.intereses.indexOf(interes.COD) > -1} />
                      <ListItemText primary={interes.ES} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
            >
              <TextField
                fullWidth
                // label="Categoría principal"
                margin="dense"
                name="categoriaTematicaUno"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.categoriaTematicaUno}
                variant="outlined"
              >
                <option value={''}>- Seleccionar Categoría Principal -</option>
                {
                  CategoriasListado.map((cat, index) => (
                    <option key={index} value={cat.COD}>{cat.ES}</option>
                  ))
                }
              </TextField>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
            >
              <TextField
                fullWidth
                // label="Categoría principal"
                margin="dense"
                name="categoriaTematicaDos"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.categoriaTematicaDos}
                variant="outlined"
              >
                <option value={''}>- Seleccionar Categoría Secundaria -</option>
                {
                  CategoriasListado.map((cat, index) => (
                    <option key={index} value={cat.COD}>{cat.ES}</option>
                  ))
                }
              </TextField>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
            >
              <TextField
                fullWidth
                // label="Categoría terciaria"
                margin="dense"
                name="categoriaTematicaTres"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.categoriaTematicaTres}
                variant="outlined"
              >
                <option value={''}>- Seleccionar Categoría Terciaria -</option>
                {
                  CategoriasListado.map((cat, index) => (
                    <option key={index} value={cat.COD}>{cat.ES}</option>
                  ))
                }
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenPortada(true)}>
                    {
                      (values.portada_imagen === '' || values.portada_imagen === null) ? 'Subir portada ES' : 'Cambiar portada ES'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsPortada}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen de portada"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openPortada}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjectsPortada(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsPortada(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenPortada(false)}
                  onSave={() => {
                    onSaveImagenPortada();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenPortadaEN(true)}>
                    {
                      (values.en_portada_imagen === '' || values.en_portada_imagen === null) ? 'Subir portada EN' : 'Cambiar portada EN'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsPortadaEN}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen de portada en inglés"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openPortadaEN}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjectsPortada(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsPortadaEN(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenPortadaEN(false)}
                  onSave={() => {
                    onSaveImagenPortadaEN();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenPortadaPO(true)}>
                    {
                      (values.po_portada_imagen === '' || values.po_portada_imagen === null) ? 'Subir portada PR' : 'Cambiar portada PR'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsPortadaPO}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen de portada en portugués"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openPortadaPO}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjectsPortada(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsPortadaPO(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenPortadaPO(false)}
                  onSave={() => {
                    onSaveImagenPortadaPO();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            </Grid>
          </CardContent>

          <CardContent>
            <CardHeader
              subheader="Información del speaker 1"
              title="SPEAKER 1"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Nombre del speaker"
                name="orador_nombre"
                onChange={handleChange}
                type="text"
                value={values.orador_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Apellido del speaker"
                margin="dense"
                name="orador_apellido"
                onChange={handleChange}
                type="text"
                value={values.orador_apellido}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="LinkedIn del speaker"
                margin="dense"
                name="orador_linkedin"
                onChange={handleChange}
                type="url"
                value={values.orador_linkedin}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenOrador(true)}>
                  {
                    (values.orador_imagen === '') ? 'Subir foto del speaker' : 'Cambiar foto del speaker'
                  }     
                  </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsOrador}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openOrador}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjects(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsOrador(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenOrador(false)}
                  onSave={() => {
                    onSaveImagenOrador();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>

            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="CV del speaker"
                margin="dense"
                name="es_orador_cv"
                multiline={true}
                onChange={handleChange}
                type="text"
                value={values.es_orador_cv}
                variant="outlined"
              />
            </Grid>
            
            </Grid>
            </CardContent>

          <CardContent>
          <CardHeader
            subheader="Información del speaker 2"
            title="SPEAKER 2"
          />
          <Grid
            container
            spacing={3}
          >
          <Grid
            item
            md={3}
            xs={6}
          >
            <TextField
              fullWidth
              margin="dense"
              label="Nombre del speaker"
              name="dos_orador_nombre"
              onChange={handleChange}
              type="text"
              value={values.dos_orador_nombre}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <TextField
              fullWidth
              label="Apellido del speaker"
              margin="dense"
              name="dos_orador_apellido"
              onChange={handleChange}
              type="text"
              value={values.dos_orador_apellido}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <TextField
              fullWidth
              label="LinkedIn del speaker"
              margin="dense"
              name="dos_orador_linkedin"
              onChange={handleChange}
              type="url"
              value={values.dos_orador_linkedin}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={3}
            xs={12}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setOpenOradorDos(true)}>
                {
                  (values.dos_orador_imagen === '') ? 'Subir foto del speaker' : 'Cambiar foto del speaker'
                }     
                </Button>

              <DropzoneDialog
                acceptedFiles={['image/*']}
                fileObjects={fileObjectsOradorDos}
                cancelButtonText={"Cancelar"}
                submitButtonText={"Adjuntar"}
                dialogTitle={"Subir imagen"}
                dropzoneText={"Hacé clic o arrastrá el archivo"}
                maxFileSize={5242880} // 5 MB
                filesLimit={1}
                open={openOradorDos}
                // onAdd={(nuevoArchivo) => {
                //   console.log(nuevoArchivo);
                //   console.log('onAdd', nuevoArchivo);
                //   setFileObjects(nuevoArchivo);
                // }}
                onDrop={(nuevoArchivo) => {
                  setFileObjectsOradorDos(nuevoArchivo);
                }}
                onDelete={deleteFileObj => {
                  console.log('onDelete', deleteFileObj);
                }}
                onClose={() => setOpenOradorDos(false)}
                onSave={() => {
                  onSaveImagenOradorDos();
                }}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNamesInPreview={true}
              />
            </div>
          </Grid>

          <Grid
            item
            md={4}
            xs={6}
          >
            <TextField
              fullWidth
              label="CV del speaker 2"
              margin="dense"
              name="dos_es_orador_cv"
              multiline={true}
              onChange={handleChange}
              type="text"
              value={values.dos_es_orador_cv}
              variant="outlined"
            />
          </Grid>
          
          </Grid>
          </CardContent>

          <CardContent>
            <CardHeader
              subheader="Información del speaker 3"
              title="SPEAKER 3"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Nombre del speaker"
                name="tres_orador_nombre"
                onChange={handleChange}
                type="text"
                value={values.tres_orador_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Apellido del speaker"
                margin="dense"
                name="tres_orador_apellido"
                onChange={handleChange}
                type="text"
                value={values.tres_orador_apellido}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="LinkedIn del speaker"
                margin="dense"
                name="tres_orador_linkedin"
                onChange={handleChange}
                type="url"
                value={values.tres_orador_linkedin}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenOradorTres(true)}>
                  {
                    (values.tres_orador_imagen === '') ? 'Subir foto del speaker' : 'Cambiar foto del speaker'
                  }     
                  </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsOradorTres}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openOradorTres}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjects(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsOradorTres(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenOradorTres(false)}
                  onSave={() => {
                    onSaveImagenOradorTres();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>

            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="CV del speaker 3"
                margin="dense"
                name="tres_es_orador_cv"
                multiline={true}
                onChange={handleChange}
                type="text"
                value={values.tres_es_orador_cv}
                variant="outlined"
              />
            </Grid>
            
            </Grid>
            </CardContent>

          <CardContent>
            <CardHeader
              subheader="Información del speaker 4"
              title="SPEAKER 4"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Nombre del speaker"
                name="cuatro_orador_nombre"
                onChange={handleChange}
                type="text"
                value={values.cuatro_orador_nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Apellido del speaker"
                margin="dense"
                name="cuatro_orador_apellido"
                onChange={handleChange}
                type="text"
                value={values.cuatro_orador_apellido}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="LinkedIn del speaker"
                margin="dense"
                name="cuatro_orador_linkedin"
                onChange={handleChange}
                type="url"
                value={values.cuatro_orador_linkedin}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenOradorCuatro(true)}>
                  {
                    (values.cuatro_orador_imagen === '') ? 'Subir foto del speaker' : 'Cambiar foto del speaker'
                  }     
                  </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsOradorCuatro}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openOradorCuatro}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjects(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsOradorCuatro(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenOradorCuatro(false)}
                  onSave={() => {
                    onSaveImagenOradorCuatro();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>

            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="CV del speaker 4"
                margin="dense"
                name="cuatro_es_orador_cv"
                multiline={true}
                onChange={handleChange}
                type="text"
                value={values.cuatro_es_orador_cv}
                variant="outlined"
              />
            </Grid>
            
            </Grid>
            </CardContent>

            <CardContent>
            <CardHeader
              subheader="Información de la empresa"
              title="EMPRESA"
            />
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Nombre de la empresa"
                  margin="dense"
                  name="nombre_empresa"
                  onChange={handleChange}
                  type="text"
                  value={values.nombre_empresa}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="WhatsApp de la empresa"
                  helperText="Ejemplo ARG: 5491155556666"
                  margin="dense"
                  name="wpp"
                  onChange={handleChange}
                  type="number"
                  value={values.wpp}
                  variant="outlined"
                />
              </Grid>
              <Grid
              item
              md={3}
              xs={12}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenLogo(true)}>
                    {
                      (values.logo_empresa === '') ? 'Subir logo de empresa' : 'Cambiar logo de empresa'
                    }                    
                    </Button>

                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  fileObjects={fileObjectsLogo}
                  cancelButtonText={"Cancelar"}
                  submitButtonText={"Adjuntar"}
                  dialogTitle={"Subir imagen"}
                  dropzoneText={"Hacé clic o arrastrá el archivo"}
                  maxFileSize={5242880} // 5 MB
                  filesLimit={1}
                  open={openLogo}
                  // onAdd={(nuevoArchivo) => {
                  //   console.log(nuevoArchivo);
                  //   console.log('onAdd', nuevoArchivo);
                  //   setFileObjectsLogo(nuevoArchivo);
                  // }}
                  onDrop={(nuevoArchivo) => {
                    setFileObjectsLogo(nuevoArchivo);
                  }}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                  onClose={() => setOpenLogo(false)}
                  onSave={() => {
                    onSaveImagenLogo();
                  }}
                  showPreviews={false}
                  showPreviewsInDropzone={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <FormControlLabel
                control={<Checkbox checked={values.mostrar_logo_certificado} onChange={handleChange} name="mostrar_logo_certificado" />}
                label="Mostrar logo en certificado"
              />
            </Grid>
            </Grid>
            </CardContent>

            <CardContent>
            <CardHeader
              subheader="Información adicional"
              title="ADICIONALES"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Link de ZOOM"
                name="zoom_link"
                onChange={handleChange}
                type="text"
                value={values.zoom_link}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="ID de Youtube"
                name="youtube"
                onChange={handleChange}
                type="text"
                value={values.youtube}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={3}
            >
              <TextField
                fullWidth
                label="Plan contratado"
                margin="dense"
                name="e_plan"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.e_plan}
                variant="outlined"
              >
                <option value={'mastertalk'}>Master-Talk</option>
                <option value={'basico'}>Básico</option>
                <option value={'intermedio'}>Intermedio</option>
                <option value={'avanzado'}>Avanzado</option>
                <option value={'premium'}>Premium</option>
              </TextField>
              {
                  planes.map(plan => {
                    if(values.e_plan === plan.nombre) {
                      return (
                        <p>$ {plan.precio}</p>
                      )
                    }
                  })
                }
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Descuento (%)"
                margin="dense"
                name="e_descuento"
                onChange={handleChange}
                type="number"
    
                value={values.e_descuento}
                variant="outlined"
              />
              {
                planes.map(plan => {
                  if(values.e_plan === plan.nombre) {
                    return (
                      <p>$ {(plan.precio * values.e_descuento) / 100}</p>
                    )
                  }
                })
              }
            </Grid>
            </Grid>
            </CardContent>

            <CardContent>
            <CardHeader
              subheader="Traducción al Portugués"
              title="PORTUGUÉS"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Titulo de la conferencia en portugués"
                name="po_titulo"
                onChange={handleChange}
                type="text"
                value={values.po_titulo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Descripción breve en portugués"
                name="po_breve_descripcion"
                onChange={handleChange}
                type="text"
                multiline={true}
                value={values.po_breve_descripcion}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Descripción larga en portugués"
                name="po_larga_descripcion"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.po_larga_descripcion}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker en portugués"
                name="po_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.po_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 2 en portugués"
                name="dos_po_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.dos_po_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 3 en portugués"
                name="tres_po_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.tres_po_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 4 en portugués"
                name="cuatro_po_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.cuatro_po_orador_cv}
                variant="outlined"
              />
            </Grid>
           
            </Grid>
            </CardContent>

            <CardContent>
            <CardHeader
              subheader="Traducción al Inglés"
              title="INGLÉS"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Titulo de la conferencia en inglés"
                name="en_titulo"
                onChange={handleChange}
                type="text"
                value={values.en_titulo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Descripción breve en inglés"
                name="en_breve_descripcion"
                onChange={handleChange}
                type="text"
                multiline={true}
                value={values.en_breve_descripcion}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="Descripción larga en inglés"
                name="en_larga_descripcion"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.en_larga_descripcion}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker en inglés"
                name="en_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.en_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 2 en inglés"
                name="dos_en_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.dos_en_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 3 en inglés"
                name="tres_en_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.tres_en_orador_cv}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                label="CV del speaker 4 en inglés"
                name="cuatro_en_orador_cv"
                onChange={handleChange}
                multiline={true}
                type="text"
                value={values.cuatro_en_orador_cv}
                variant="outlined"
              />
            </Grid>
           
            </Grid>
            </CardContent>

            <CardContent>
            <CardHeader
              subheader="Pago o gratuito"
              title="Certificado"
            />
            <Grid
              container
              spacing={3}
            >
            <Grid
              item
              md={3}
              xs={12}
            >
              <FormControlLabel
                control={<Checkbox checked={values.certificado_gratuito} onChange={handleChange} name="certificado_gratuito" />}
                label="Certificado gratuito"
              />
            </Grid>
            {
              values.certificado_gratuito ? (
                <>
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setOpenLogoSponsor(true)}>
                          {
                            (values.certificado_sponsor === '') ? 'Subir logo de empresa' : 'Cambiar logo de empresa'
                          }                    
                          </Button>

                      <DropzoneDialog
                        acceptedFiles={['image/*']}
                        fileObjects={fileObjectsLogoSponsor}
                        cancelButtonText={"Cancelar"}
                        submitButtonText={"Adjuntar"}
                        dialogTitle={"Subir imagen"}
                        dropzoneText={"Hacé clic o arrastrá el archivo"}
                        maxFileSize={5242880} // 5 MB
                        filesLimit={1}
                        open={openLogoSponsor}
                        // onAdd={(nuevoArchivo) => {
                        //   console.log(nuevoArchivo);
                        //   console.log('onAdd', nuevoArchivo);
                        //   setFileObjectsLogoSponsor(nuevoArchivo);
                        // }}
                        onDrop={(nuevoArchivo) => {
                          setFileObjectsLogoSponsor(nuevoArchivo);
                        }}
                        onDelete={deleteFileObj => {
                          console.log('onDelete', deleteFileObj);
                        }}
                        onClose={() => setOpenLogoSponsor(false)}
                        onSave={() => {
                          onSaveImagenLogoSponsor();
                        }}
                        showPreviews={false}
                        showPreviewsInDropzone={true}
                        showFileNamesInPreview={true}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      label="Desde (UTC-0)"
                      type="datetime-local" 
                      name="certificado_gratuito_desde"
                      // defaultValue="2017-05-24T10:30"
                      className={classes.textField}
                      value={values.certificado_gratuito_desde}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      label="Hasta (UTC-0)"
                      type="datetime-local" 
                      name="certificado_gratuito_hasta"
                      // defaultValue="2017-05-24T10:30"
                      className={classes.textField}
                      value={values.certificado_gratuito_hasta}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                </>
              ) : null
            }
            </Grid>
            </CardContent>
            <CardContent>
              <CardHeader
                subheader="Será publicado automáticamente una semana después"
                title="Biblioteca de contenidos"
              />
              <Grid
                container
                spacing={3}
              >
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Titulo en español"
                    name="b_es_titulo"
                    onChange={handleChange}
                    type="text"
                    value={values.b_es_titulo}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Titulo en inglés"
                    name="b_en_titulo"
                    onChange={handleChange}
                    type="text"
                    value={values.b_en_titulo}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Titulo en portugués"
                    name="b_po_titulo"
                    onChange={handleChange}
                    type="text"
                    value={values.b_po_titulo}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Descripción en español"
                    multiline={true}
                    name="b_es_descripcion"
                    onChange={handleChange}
                    type="text"
                    value={values.b_es_descripcion}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Descripción en inglés"
                    multiline={true}
                    name="b_en_descripcion"
                    onChange={handleChange}
                    type="text"
                    value={values.b_en_descripcion}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Descripción en portugués"
                    name="b_po_descripcion"
                    onChange={handleChange}
                    multiline={true}
                    type="text"
                    value={values.b_po_descripcion}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenPortadaBiblioteca(true)}>
                        {
                          (values.b_es_portada === '') ? 'Subir portada ES' : 'Cambiar portada ES'
                        }                    
                        </Button>
                    <DropzoneDialog
                      acceptedFiles={['image/*']}
                      fileObjects={fileObjectsPortadaBiblioteca}
                      cancelButtonText={"Cancelar"}
                      submitButtonText={"Adjuntar"}
                      dialogTitle={"Subir imagen"}
                      dropzoneText={"Hacé clic o arrastrá el archivo"}
                      maxFileSize={1048576} // 1 MB
                      filesLimit={1}
                      open={openPortadaBiblioteca}
                      onDrop={(nuevoArchivo) => {
                        setFileObjectsPortadaBiblioteca(nuevoArchivo);
                      }}
                      onDelete={deleteFileObj => {
                        console.log('onDelete', deleteFileObj);
                      }}
                      onClose={() => setOpenPortadaBiblioteca(false)}
                      onSave={() => {
                        onSaveImagenPortadaBiblioteca();
                      }}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenPortadaBibliotecaEN(true)}>
                        {
                          (values.b_en_portada === '') ? 'Subir portada EN' : 'Cambiar portada EN'
                        }                    
                        </Button>
                    <DropzoneDialog
                      acceptedFiles={['image/*']}
                      fileObjects={fileObjectsPortadaBibliotecaEN}
                      cancelButtonText={"Cancelar"}
                      submitButtonText={"Adjuntar"}
                      dialogTitle={"Subir imagen"}
                      dropzoneText={"Hacé clic o arrastrá el archivo"}
                      maxFileSize={1048576} // 1 MB
                      filesLimit={1}
                      open={openPortadaBibliotecaEN}
                      onDrop={(nuevoArchivo) => {
                        setFileObjectsPortadaBibliotecaEN(nuevoArchivo);
                      }}
                      onDelete={deleteFileObj => {
                        console.log('onDelete', deleteFileObj);
                      }}
                      onClose={() => setOpenPortadaBibliotecaEN(false)}
                      onSave={() => {
                        onSaveImagenPortadaBibliotecaEN();
                      }}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenPortadaBibliotecaPO(true)}>
                        {
                          (values.b_po_portada === '') ? 'Subir portada PR' : 'Cambiar portada PR'
                        }                    
                        </Button>
                    <DropzoneDialog
                      acceptedFiles={['image/*']}
                      fileObjects={fileObjectsPortadaBibliotecaPO}
                      cancelButtonText={"Cancelar"}
                      submitButtonText={"Adjuntar"}
                      dialogTitle={"Subir imagen"}
                      dropzoneText={"Hacé clic o arrastrá el archivo"}
                      maxFileSize={1048576} // 1 MB
                      filesLimit={1}
                      open={openPortadaBibliotecaPO}
                      onDrop={(nuevoArchivo) => {
                        setFileObjectsPortadaBibliotecaPO(nuevoArchivo);
                      }}
                      onDelete={deleteFileObj => {
                        console.log('onDelete', deleteFileObj);
                      }}
                      onClose={() => setOpenPortadaBibliotecaPO(false)}
                      onSave={() => {
                        onSaveImagenPortadaBibliotecaPO();
                      }}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item lg={2} md={3} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={values.b_destacado} onChange={handleChange} name="b_destacado" />}
                    label="Destacado"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    label="Destacado (orden)"
                    margin="dense"
                    name="b_orden_destacado"
                    onChange={handleChange}
                    type="number"
                    value={values.b_orden_destacado}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={2} md={3} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={values.b_patrocinado} onChange={handleChange} name="b_patrocinado" />}
                    label="Sponsor"
                  />
                </Grid>
                
                <Grid
                  item
                  md={2}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    label="Sponsor (orden)"
                    margin="dense"
                    name="b_orden_patrocinado"
                    onChange={handleChange}
                    type="number"
                    value={values.b_orden_patrocinado}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Guardar
          </Button>
          
          {errores ? <Alert severity="error">{errores}</Alert> : null}
        </CardActions>
      </form>
    </Card>

  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
