export const CategoriasListado = [
    {
        COD: "patro",
        ES: "PATROCINADAS",
        PR: "PATROCINADAS",
        EN: "SPONSORED"
    },
    {
        COD: "neuro",
        ES: "NEUROLOGÍA",
        PR: "NEUROLOGIA",
        EN: "NEUROLOGY"
    },
    {
        COD: "endoc",
        ES: "ENDOCRINOLOGÍA",
        PR: "ENDOCRINOLOGIA",
        EN: "ENDOCRINOLOGY"
    },
    {
        COD: "oncol",
        ES: "ONCOLOGÍA",
        PR: "ONCOLOGIA",
        EN: "ONCOLOGY"
    },
    {
        COD: "atpac",
        ES: "ATENCION AL PACIENTE",
        PR: "ASSISTÊNCIA AO PACIENTE",
        EN: "PATIENT CARE"
    },
    {
        COD: "cardio",
        ES: "CARDIOLOGÍA",
        PR: "CARDIOLOGIA",
        EN: "CARDIOLOGY"
    },
    {
        COD: "medalt",
        ES: "MEDICINA ALTERNATIVA",
        PR: "MEDICINA ALTERNATIVA",
        EN: "ALTERNATIVE MEDICINE"
    },
    {
        COD: "infect",
        ES: "INFECTOLOGIA",
        PR: "INFECTOLOGIA",
        EN: "INFECTOLOGY"
    },
    {
        COD: "covpan19",
        ES: "COVID PANDEMICO 19",
        PR: "COVID PANDEMIC 19",
        EN: "PANDEMIC COVID 19"
    },
    {
        COD: "digita",
        ES: "Digitalización",
        PR: "Digitalização",
        EN: "DIGITALIZATION"
    },
    {
        COD: "labo",
        ES: "LABORATORIO",
        PR: "LABORATÓRIO",
        EN: "LABORATORY"
    },
    {
        COD: "biomolec",
        ES: "BIOLOGÍA MOLECULAR",
        PR: "BIOLOGIA MOLECULAR",
        EN: "MOLECULAR BIOLOGY"
    },
    {
        COD: "dolor",
        ES: "DOLOR",
        PR: "DOR",
        EN: "PAIN"
    },
    {
        COD: "diabe",
        ES: "DIABETES",
        PR: "DIABETES",
        EN: "DIABETES"
    },
    {
        COD: "blockch",
        ES: "BLOCKCHAIN",
        PR: "BLOCKCHAIN",
        EN: "BLOCKCHAIN"
    },

    {
        COD: "asun-reg-cal",
        ES: "ASUNTOS REGULATORIOS Y CALIDAD",
        PR: "ASUNTOS REGULATÓRIOS E QUALIDADE",
        EN: "REGULATORY AFFAIRS AND QUALITY"
    },
    {
        COD: "tec-dig",
        ES: "TECNOLOGIA, IA Y SALUD DIGITAL",
        PR: "TECNOLOGIA, IA E SAÚDE DIGITAL",
        EN: "TECHNOLOGY, AI, AND DIGITAL HEALTH"
    },
    {
        COD: "poc",
        ES: "POC",
        PR: "POC",
        EN: "POC"
    },
    {
        COD: "bco-sge",
        ES: "BANCO DE SANGRE",
        PR: "BANCO DE SANGUE",
        EN: "BLOOD BANK"
    },
    {
        COD: "hemato",
        ES: "HEMATOLOGIA Y HEMOSTASIA",
        PR: "HEMATOLOGIA E HEMOSTASIA",
        EN: "HEMATOLOGY AND HEMOSTASIS"
    },
    {
        COD: "mngmt",
        ES: "MANAGEMENT EN SALUD",
        PR: "GESTÃO EM SAÚDE",
        EN: "HEALTH MANAGEMENT"
    },
    {
        COD: "uci",
        ES: "UCI - EMERGENCIAS",
        PR: "UTI - EMERGÊNCIAS",
        EN: "ICU - EMERGENCIES"
    }

];