import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import clienteAxios from '../../../../config/axios';
import Alert from '@material-ui/lab/Alert';
import {ListaIntereses} from '../../../../InteresesListado.js';
import {CategoriasListado} from '../../../../CategoriasListado.js';

import {
  Input,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip, FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const mensajeSwal = withReactContent(Swal);


  useEffect(() => {
    rellenarCamposEdicion();
    // eslint-disable-next-line
  }, []);

  async function rellenarCamposEdicion() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    if(id) {
      setEditandoId(id);
      await clienteAxios.get(`/api/banners/id/${id}`)
        .then(resultado => {
          console.log(resultado)
          setValues(resultado.data.resp)

        })
        .catch(error => {
          console.log(error)
        })
    }


  }

  const [errores, setErrores] = useState(null);
  const [editandoId, setEditandoId] = useState(null);
  const [values, setValues] = useState({
      es_imagen: '',
      en_imagen: '',
      po_imagen: '',
      link: '',
      order: ''
    });


  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();
  
    if(editandoId === null) {
      crearBanner();
    } else {
      editarBanner();
    }
  }

  async function editarBanner() {
    try {
      const postEvento = await clienteAxios.put(`/api/banners/${values.id}`, values);
      mensajeSwal.fire({
        title: '¡Excelente!',
        text: `El banner fue actualizado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(()=> {
        window.location.replace(`/banners`);
      });
    } catch (error) {
      console.log(error);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `Hubo un error`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  async function crearBanner() {
    try {
      const postEvento = await clienteAxios.post(`/api/banners/`, values);
      mensajeSwal.fire({
        title: '¡Excelente!',
        text: `El banner fue creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(()=> {
        window.location.replace(`/banners`);
      });
    } catch (error) {
      console.log(error);
      mensajeSwal.fire({
        title: 'Ups...',
        text: `Hubo un error`,
        icon: 'error',
        timer: 3000
      });
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        method="post"
        noValidate
        onSubmit={(e) => enviarFormulario(e)}
      >
        <CardContent>
        <CardHeader
          title={`${editandoId === null ? 'CREAR BANNER' : 'EDITAR BANNER'}`}
        />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="ES - URL de imagen"
                margin="dense"
                name="es_imagen"
                onChange={handleChange}
                required
                value={values.es_imagen}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="EN - URL de imagen"
                margin="dense"
                name="en_imagen"
                onChange={handleChange}
                value={values.en_imagen}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="PR - URL de imagen"
                margin="dense"
                name="po_imagen"
                onChange={handleChange}
                value={values.po_imagen}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="Link de redirección"
                margin="dense"
                name="link"
                onChange={handleChange}
                value={values.link}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="Orden"
                margin="dense"
                name="order"
                type="number"
                onChange={handleChange}
                required
                value={values.order}
                variant="outlined"
              />
            </Grid>
          </Grid> 
          </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            {editandoId === null ? 'CREAR' : 'ACTUALIZAR'}
          </Button>
          
          {errores ? <Alert severity="error">{errores}</Alert> : null}
        </CardActions>
      </form>
    </Card>

  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
