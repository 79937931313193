import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import clienteAxios from '../../config/axios';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  ComprasDash
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  aboutTalleres: {
    fontFamily: 'Roboto' 
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const [estadisticas, setEstadisticas] = useState({
    totalCharlas: 0,
    totalInscripciones: 0,
    totalSuscriptores: 0,
    totalIngresos: 0,
    totalDescuentos: 0,
    totalCharlasVisibles: 0
  });

  useEffect(() => {
    const traerDatos = async () => {
      const estadisticasDB = await clienteAxios.get('/api/estadisticas/generales');
      setEstadisticas(estadisticasDB.data.estadisticas);
    }
    traerDatos();
  //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <h2
          className={classes.aboutTalleres}>PARTICIPACIÓN</h2>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget
            onClick={() => window.location.href = '/usuarios'}
            style={{cursor: 'pointer'}}
            inscriptosNewsletter={estadisticas.totalRegistrados} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers
          onClick={() => window.location.href = '/inscripciones'}
          style={{cursor: 'pointer'}}
          inscriptosCharlas={estadisticas.totalInscripciones} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress
          onClick={() => window.location.href = '/agenda'}
          style={{cursor: 'pointer'}}
          conferenciasTotales={estadisticas.totalCharlas} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit
          onClick={() => window.location.href = '/agenda'}
          style={{cursor: 'pointer'}}
           totalCharlasVisibles={estadisticas.totalCharlasVisibles} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <h2
            onClick={() => window.location.href = '/ingresos'}
            style={{cursor: 'pointer'}}
          className={classes.aboutTalleres}>INGRESOS</h2>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash 
          onClick={() => window.location.href = '/ingresos'}
          style={{cursor: 'pointer'}}
           numero={estadisticas.totalIngresos} texto="SUBTOTAL"  color="azul" />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash
            onClick={() => window.location.href = '/ingresos'}
            style={{cursor: 'pointer'}}
            numero={estadisticas.totalDescuentos} texto="DESCUENTOS HECHOS" color="rojo" />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash
            onClick={() => window.location.href = '/ingresos'}
            style={{cursor: 'pointer'}}
            numero={estadisticas.totalIngresos - estadisticas.totalDescuentos} texto="TOTAL" color="verde" />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <h2
          className={classes.aboutTalleres}>CERTIFICADOS</h2>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash 
          onClick={() => window.location.href = '/certificados'}
          style={{cursor: 'pointer'}}
           numero={estadisticas.pendientesCertificados + estadisticas.pagadosCertificados} texto="DISPONIBLES" color="azul" />
        </Grid>
        {/* <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash
            onClick={() => window.location.href = '/certificados'}
            style={{cursor: 'pointer'}}
            numero={estadisticas.pendientesCertificados} texto="NO PAGOS" color="rojo" />
        </Grid> */}
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComprasDash
            onClick={() => window.location.href = '/certificados'}
            style={{cursor: 'pointer'}}
            numero={estadisticas.pagadosCertificados} texto="PAGOS" color="verde" />
        </Grid>
        </Grid>
      </div>
  );
};

export default Dashboard;
